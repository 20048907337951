jQuery(document).ready(function() {
	jQuery(".bxslider").bxSlider({
		pager: false,
		//auto: false,
		auto: (jQuery(".bxslider").children().length < 2) ? false : true,
        touchEnabled:(jQuery(".bxslider").children().length < 2) ? false : true,
		controls:false,
        adaptiveHeight:true,
	    mode: "fade",
	    pause: 5000,
	    speed: 1000
    });

     jQuery(".mobile-menu a").on("click touchstart", function (event) {
		event.preventDefault();
		event.stopPropagation();
		jQuery("body").toggleClass("open-menu");
	});
   jQuery(".site-header .header-right").on("click touchstart", function (event) {
		event.stopPropagation();
	});

    jQuery(document).on("click", function () {
        jQuery("body").removeClass("open-menu");

    });

     jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){

	   if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
	   {
		event.preventDefault();
		event.stopPropagation();
		jQuery(this).parent().addClass("open").siblings().removeClass("open");
	   }
	  });

    jQuery(".touch .cover a").on("click touchstart",function(event){

    if(!jQuery(this).parent().hasClass("open"))
    {
        event.preventDefault();
		event.stopPropagation();
        jQuery(".cover").removeClass("open");
        jQuery(this).parent().addClass("open").siblings().removeClass("open");

    }
    });

});


jQuery(window).load(function () {
	global();
    jQuery(".banner-section").addClass("active");

});
jQuery(window).resize(function () {
	global();
});

function global(){
	equalheight(".block-single .cover-no");
    footerH = jQuery(".site-footer").outerHeight();
    jQuery(".welcome-section-no").css({"margin-bottom": footerH});
    jQuery(".content-section").css({"margin-bottom": footerH});
}

equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel,
            topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).height("auto");
            topPostion = jQueryel.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.height();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.height()) ? (jQueryel.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });
    } else {
        jQuery(container).height("auto");
    }
};
